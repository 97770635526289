import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import * as AcfLayout from '../components/Acf';
import { PageHeader } from '../components/PageHeader';
import ProjectList from '../components/Posts/ProjectList';
import { decodeEntities } from '../utils/helpers';

const AcfComponent = ({ location, componentName, item, pageContext }) => {
  // If component does not exist in acf folder, print error message
  const ComponentName = AcfLayout[componentName];
  const componentTitle = item.__typename ? item.__typename.replace('WordPressAcf_','') : 'No name';
  if (!ComponentName) {
        return (
          <section className="page missing"><div className="inner">The ACF component <strong>"{componentTitle}"</strong> is missing. <span>&#128540;</span></div></section>
        )
  }else{
    return (
      <ComponentName
        location={location}
        pageContext={pageContext}
        {...item}
      />
    );
  }
};

export default class ProjectIndexPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props;
    const { allWordpressWpCaseStudies, categories, wordpressWpSettings } = data;
    const { layout = {} } = data.wordpressPage.acf;
    const { title: siteTitle } = wordpressWpSettings;
    const { edges: posts } = allWordpressWpCaseStudies
    // Experiencing an issue with Yoast NOT sending all data to ACF, so hard coding the title for the blog page
    const caseStudyPageTitle = "Industry Case Studies » Amped Digital Signage";
    return (
      <Layout location={location}>
        <SEO title={decodeEntities(caseStudyPageTitle)} />
        {layout && layout.map((item, index) => {
          if (!item.__typename) return null;
          const layoutComponentName = item.__typename.replace('WordPressAcf_','');
          return (
            <AcfComponent
              key={index}
              componentName={layoutComponentName}
              item={item}
              location={location}
              pageContext={pageContext}
            />
          );
        })}
        <ProjectList
          posts={posts}
          title="Latest case studies"
          pageContext={pageContext}
          categories={categories.edges}
          siteMetadata={wordpressWpSettings}
          pathPrefix="case-studies"
        />
      </Layout>
    )
  }
}

ProjectIndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressWpCaseStudies: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ProjectIndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title,
      wordpressUrl
      siteUrl
    }
    allWordpressWpCaseStudies(
      sort: { fields: title, order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    wordpressPage(slug: {eq: "case-studies"}) {
      title
      ...AcfQuery
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          count
        }
      }
    }
  }
`
